@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Variables
$bee: #FBB518;
$family-merriweather: "Merriweather", sans-serif;
$family-montserat: "Montserrat", sans-serif;

// Update Bulma's global variables
$family-sans-serif: $family-montserat;

$normal: #FFF;
$normal-invert: #2A88F6;

$gray: #969696;
$gray-invert: white;

$primary: $bee;
$secondary: #d59713;
$primary-invert: white;

$danger: #ef4444;
$danger-invert: white;

$dark: #000;
$dark-invert: white;


$size-6: 0.97rem;

@import "~bulma/sass/utilities/mixins";


.has-navbar-fixed-top {
  padding-top: 3.25rem;

}

.navbar .navbar-brand > a.navbar-item {
  transition: transform 100ms linear !important;
}

.navbar .navbar-brand > a.navbar-item:hover {
  background-color: transparent !important;
  transform: scale(1.04);
}

.navbar-link {
  font-size: 0.85rem !important;
}

.navbar-dropdown {
  border-top-width: 0px !important;
}

.menu-list a.is-active {
  background-color: $primary !important;
}

// Buttons

.button {
  font-size: 0.85rem !important;
  border-radius: 0.7em !important;
  border-width: 2px !important;
  border-color: $primary !important;
  background-color: $primary !important;
  color: $primary-invert !important;
  transition: background-color 100ms linear !important;

  &.is-large {
    font-weight: 400;
    font-size: 20px !important;

    @include mobile {
      font-size: 18px !important;
    }
  }

  &.is-danger {
    color: $danger-invert !important;
    border-color: $danger !important;
    background-color: $danger !important;
  }

  &.is-dark {
    color: $dark-invert !important;
    border-color: $dark !important;
    background-color: $dark !important;
  }

  &.is-gray {
    color: $gray-invert !important;
    border-color: $gray !important;
    background-color: $gray !important;
  }

  &.is-normal {
    color: $normal-invert !important;
    border-color: $normal-invert !important;
    background-color: $normal !important;
  }

  &.is-small-ico {
    border-radius: 50% !important; //0.4em !important;
    padding: 0.2em;
    border-width: 1px !important;
    height: 1.4em !important;
    width: 1.4em !important;
    line-height: 0.5 !important;
    font-size: 1rem !important;
  }
}

.button:hover:not([disabled]) {
  color: $primary !important;
  background-color: $primary-invert !important;

  &.is-danger {
    color: $danger !important;
    background-color: $danger-invert !important;
  }

  &.is-dark {
    color: $dark !important;
    background-color: $dark-invert !important;
  }

  &.is-gray {
    color: $gray !important;
    background-color: $gray-invert !important;
  }

  &.is-normal {
    color: $normal !important;
    background-color: $normal-invert !important;
  }
}

.button.is-outlined {
  border-color: $primary-invert !important;
  background-color: transparent !important;
  color: $primary-invert !important;
}

.button.is-outlined:hover:not([disabled]) {
  color: $primary !important;
  background-color: $primary-invert !important;
}

.button.without-border {
  border-width: 0 !important;
}

.button.is-a {
  border-width: 0 !important;
  background-color: transparent !important;
  color: $primary !important;
}

.button.is-a:hover:not([disabled]) {
  background-color: transparent !important;
  color: $secondary !important;
}

.button.is-a-red {
  border-width: 0 !important;
  background-color: transparent !important;
  color: #ef4444 !important;
}

.button.is-a-red:hover:not([disabled]) {
  background-color: transparent !important;
  color: #c72424 !important;
}

// utility styles

.has-rounded-corners {
  border-radius: .375em;
}

.cover {
  object-fit: cover;
}

.has-shadow {
  box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
}

.has-stripes {
  background: repeating-linear-gradient(
                  45deg,
                  #ccc,
                  #ccc 5px,
                  #ffff 5px,
                  #ffff 10px
  );
}

.no-color {
  background: repeating-linear-gradient(
                  -45deg,
                  #f1f1f1,
                  #f1f1f1 5px,
                  #ffff 5px,
                  #ffff 10px
  );
}

.hive-card {
  border-radius: 4px;
  border-style: solid;
  border-width: 0 0 0 4px;
}

.image.is-20x20 {
  height: 20px;
  width: 20px;
}

.select {
  width: 100%;
}

select {
  width: 100%;
}

.merri {
  font-family: $family-merriweather;
}

.serif {
  font-family: $family-sans-serif;
}

.has-text-shadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.orange {
    color: #E28100 !important;
}

.home {

  h1 {
    //font-size: 3.5rem !important;
    font-size: 64px !important;

    @include mobile {
      font-size: 44px !important;
    }

    font-family: $family-merriweather;
    font-weight: 900;

    &.bold {
      font-weight: 700;
    }
  }

  h2 {
    //font-size: 1.7rem !important;
    font-size: 36px !important;

    @include mobile {
      font-size: 30px !important;
    }

    font-weight: 600;
    color: black;

    &.regular {
      font-weight: 400;
    }

    &.bold {
      font-weight: 700;
    }

    &.orange {
      color: #E28100;
    }

    &.is-white {
      color: white;
    }
  }

  .stats {
    font-size: 2.5rem !important;
    font-weight: 600;
  }

  h3 {
    font-weight: 700;
    //font-size: 1.4875rem !important;
    font-size: 28px !important;
    color: black;

    @include mobile {
      font-size: 22px !important;
    }

    &.is-white {
      color: white;
    }
  }

  h3.medium {
    font-weight: 400;
    font-size: 28px !important;

    @include mobile {
      font-size: 22px !important;
    }
  }

  h3.light {
    font-weight: 300;
    font-size: 28px !important;

    @include mobile {
      font-size: 22px !important;
    }
  }

  .link {
    color: black;
  }

  li {
    font-weight: 400;
    font-size: 20px !important;

    @include mobile {
      font-size: 18px !important;
    }
  }

  p {
    //font-size: 1.1rem !important;
    font-size: 20px !important;

    @include mobile {
      font-size: 18px !important;
    }

    &.small {
      font-size: 18px !important;

      @include mobile {
        font-size: 18px !important;
      }
    }
  }

  hr {
    background-color: $bee;
    width: 10rem;
    height: 3px
  }
}

.footer {
  padding: 3rem 0 6rem !important;
  p {
    color: white;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    font-size: 1.1rem;
  }

  .hero-body {
    z-index: 2;
  }
}

.cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}


// omit notification.sass
@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/base/_all";
@import "~bulma/sass/elements/box";
@import "~bulma/sass/elements/button";
@import "~bulma/sass/elements/container";
@import "~bulma/sass/elements/content";
@import "~bulma/sass/elements/form";
@import "~bulma/sass/elements/icon";
@import "~bulma/sass/elements/image";
@import "~bulma/sass/elements/other";
@import "~bulma/sass/elements/progress";
@import "~bulma/sass/elements/table";
@import "~bulma/sass/elements/tag";
@import "~bulma/sass/elements/title";
@import "~bulma/sass/form/_all";
@import "~bulma/sass/components/_all";
@import "~bulma/sass/grid/_all";
@import "~bulma/sass/helpers/_all";
@import "~bulma/sass/layout/_all";


.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

// Spinner

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0.0)
  }
  50% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.vatt {
  vertical-align: text-top;
}

.upload-input {
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
}

.note-hidden {
  display: none;
}

.note:hover {
}

.note:hover .note-hidden {
  display: inline;
}

.entry-hidden {
  display: none;
}

.entry:hover {
}

.entry:hover .entry-hidden {
  display: inline;
}

@import '~react-notifications-component/dist/theme.css';
